.container {
  display: none;
}

@media print {
  .container {
    display: block;
    page-break-after: always;
  }
}

.checkedContainer {
  font-size: 1rem;
  display: flex;
  margin-top: 0.2rem;
  border: 1px solid black;
  padding: 0.5rem;
}

.container {
  padding: 1rem;
  font-size: 0.7em;
  flex-direction: column;
}

.container h1 {
  margin: 0rem;
  font-size: medium;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container h2 {
  font-size: medium;
}

.infoContainer {
  border: black solid 1px;
  padding: 0.5rem;
  display: grid;
  grid-template-areas:
    "company company make reg-no"
    "address address model fleet-no"
    "address address body-type odo-reading"
    ". . year chassis-no"
    "tachograph-calibration-date . inspection-date iso-week-no";
}

.infoFieldLabel {
  padding-right: 1rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.7);
}

.infoFieldValue {
  padding-right: 0.5rem;
}

.partTitle {
  text-align: center;
  width: 100%;
}

.checksContainer {
  display: grid;
  page-break-after: always;
  width: 98%;
  margin: auto;
  gap: 0.3rem;
  grid-auto-columns: 50% 50%;
  grid-template-rows: max-content;
  grid-template-areas:
    "cab-interior ground-level"
    "cab-interior ground-level"
    "cab-interior ground-level"
    "cab-interior ground-level"
    "cab-interior ground-level"
    "cab-interior ground-level"
    "cab-interior ground-level"
    "cab-interior ground-level"
    "cab-interior ground-level"
    "cab-interior ground-level"
    "cab-interior ground-level"
    "cab-interior ground-level"
    "cab-interior ground-level"
    "cab-interior ."
    "cab-interior ."
    "cab-interior ."
    "cab-interior under-alongside-vehicle"
    "cab-interior under-alongside-vehicle"
    "cab-interior under-alongside-vehicle"
    "cab-interior under-alongside-vehicle"
    "cab-interior under-alongside-vehicle"
    "cab-interior under-alongside-vehicle"
    "cab-interior under-alongside-vehicle"
    "cab-interior under-alongside-vehicle"
    "cab-interior under-alongside-vehicle"
    "cab-interior under-alongside-vehicle"
    "cab-interior under-alongside-vehicle"
    "cab-interior under-alongside-vehicle"
    "cab-interior under-alongside-vehicle"
    "cab-interior under-alongside-vehicle"
    ". under-alongside-vehicle"
    "cab-exterior under-alongside-vehicle"
    "cab-exterior under-alongside-vehicle"
    "cab-exterior under-alongside-vehicle"
    "cab-exterior under-alongside-vehicle"
    "cab-exterior under-alongside-vehicle"
    "cab-exterior under-alongside-vehicle"
    "cab-exterior under-alongside-vehicle"
    "cab-exterior under-alongside-vehicle"
    "cab-exterior under-alongside-vehicle"
    "cab-exterior under-alongside-vehicle"
    "cab-exterior under-alongside-vehicle"
    ". under-alongside-vehicle"
    "engine-compartment under-alongside-vehicle"
    "engine-compartment under-alongside-vehicle"
    "engine-compartment under-alongside-vehicle"
    "engine-compartment under-alongside-vehicle"
    "engine-compartment under-alongside-vehicle"
    "engine-compartment under-alongside-vehicle"
    "engine-compartment under-alongside-vehicle"
    "engine-compartment under-alongside-vehicle"
    "engine-compartment under-alongside-vehicle"
    "engine-compartment under-alongside-vehicle"
    "engine-compartment under-alongside-vehicle"
    "engine-compartment under-alongside-vehicle"
    "engine-compartment under-alongside-vehicle"
    "engine-compartment .";
}
.checksContainerPage2 {
  padding: 5em 0 5em 0;
  width: 98%;
  margin: auto;
  display: grid;
  gap: 0.3rem;
  grid-auto-columns: 50% 50%;
  grid-template-rows: max-content;
  grid-template-areas:
    "under-alongside-vehicle--continued- tyres"
    "under-alongside-vehicle--continued- tyres"
    "under-alongside-vehicle--continued- tyres"
    "under-alongside-vehicle--continued- tyres"
    "under-alongside-vehicle--continued- tyres"
    "under-alongside-vehicle--continued- tyres"
    "under-alongside-vehicle--continued- tyres"
    "under-alongside-vehicle--continued- tyres"
    "under-alongside-vehicle--continued- tyres"
    "under-alongside-vehicle--continued- tyres"
    "under-alongside-vehicle--continued- tyres"
    "under-alongside-vehicle--continued- tyres"
    "under-alongside-vehicle--continued- tyres"
    "under-alongside-vehicle--continued- tyres"
    "under-alongside-vehicle--continued- tyres"
    "under-alongside-vehicle--continued- tyres"
    "under-alongside-vehicle--continued- tyres";
}

.check {
  display: grid;
  grid-template-columns: auto 2rem 2rem;
  grid-auto-rows: 1.4em;
}

.categoryContainer {
  border: black solid 1px;
}

.fault {
  white-space: nowrap;
}

.tyreCheckTitle {
  padding: 0.5rem;
}

.mc {
  border: black solid 1px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.im {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.categoryHeading {
  display: grid;
  grid-template-columns: auto 2rem 2rem;
  grid-template-rows: auto;
}

.categoryName {
  border-bottom: black solid 1px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imHeading {
  border-bottom: black solid 1px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mcHeading {
  border-left: black solid 1px;
  border-right: black solid 1px;
  border-bottom: black solid 1px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tyresContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-top: black solid 1px;
}

.tyreChecksContainer {
  display: flex;
}

.onVehicleTyres {
  flex-grow: 1;
  flex-shrink: 1;
  display: grid;
  align-items: center;
  grid-gap: 1rem;
  grid-template-columns: none;
  grid-template-areas:
    "tyre1 tyre3 tyre7 tyre11"
    ". . tyre6 tyre10"
    ". . tyre5 tyre9"
    "tyre0 tyre2 tyre4 tyre8";
}

.onVehicleTyre {
  display: flex;
  flex-direction: column;
}

.tyreContainer {
  display: flex;
}

.rotated {
  /* position: absolute; */
  transform: rotate(270deg);
}

.offVehicleTyres {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0 0 0 2rem; */
  flex-shrink: 1;
}

.tyreInputs {
  display: flex;
  align-items: center;
}

.tyreInput {
  display: flex;
  border: 1px solid black;
  height: 1rem;
  width: 1.8rem;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.tyreLabels {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.tyreLabel {
  display: flex;
}

.rectificationContainer {
  display: flex;
}

.rectificationsContainer {
  margin-bottom: 5rem;
}

table,
td,
th {
  border: 1px solid black;
}

table {
  border-collapse: collapse;
}

/* Prevent collapse of empty rows */
td:empty::after {
  content: "\00a0";
}
